.home {
    min-height: 100vh;
    height: auto;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.home::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/images/backgroundv2.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
}

.header-home {
    padding-top: 1.5em;
}

.products {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 1em;
}

@media (max-width: 768px) {
    .home {
        min-height: 100vh;
        width: 100vw;
        height: auto;
        overflow: hidden;
        position: fixed;
    }
    .home::before {
        background-image: url('../assets/images/mobile_backgroundv2.jpg');
        filter: blur(2px);
    }
    .products {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 1.5em;
    }
}